<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "App",
  methods: {
    // Method to generate page titles
    titleTemplate(title = 'Dashonic') {
      console.log(title);
      
      // Check if title is a function and call it if so
      title = typeof title === "function" ? title(this.$store) : title;
      // Return the formatted title
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
};
</script>
