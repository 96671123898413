import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import store from '@/state/store'

import BootstrapVueNext from 'bootstrap-vue-next';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import 'bootstrap/dist/js/bootstrap.bundle';

import vClickOutside from "click-outside-vue3";
import VueFeather from 'vue-feather';
import VueApexCharts from 'vue3-apexcharts';
import Vuelidate from '@vuelidate/core';
import VueEasyLightbox from 'vue-easy-lightbox'
import Vue3Toastify from 'vue3-toastify';
import * as VueGoogleMaps from 'vue3-google-map';

import { vMaska } from 'maska';

// Firebase configuration
import { initFirebaseBackend } from './authUtils';
import { configureFakeBackend } from './helpers/fake-backend';

if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
  initFirebaseBackend(firebaseConfig);
} else {
  configureFakeBackend();
}

const firebaseConfig = {
  apiKey: process.env.VUE_APP_APIKEY,
  authDomain: process.env.VUE_APP_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
  projectId: process.env.VUE_APP_PROJECTId,
  storageBucket: process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_APPId,
  measurementId: process.env.VUE_APP_MEASUREMENTID
};

// Vue.config.productionTip = false

import '@/assets/scss/app.scss';

// App setup
const app = createApp(App);
app.config.productionTip = false;
app.use(router);
app.use(store);
app.use(i18n);
app.use(BootstrapVueNext);
app.use(vClickOutside);
app.use(VueApexCharts);
app.use(Vuelidate);
app.use(VueEasyLightbox);
app.use(Vue3Toastify);
app.component('apexchart', VueApexCharts)
app.component(VueFeather.name, VueFeather);
app.directive('maska', vMaska)
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
})
app.mount('#app');
